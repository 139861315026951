import { DatePicker, Tabs, TimePicker } from "antd";
import React, { useEffect, useMemo, useState, useContext } from "react";
import ServiceType from "./servicetype";
import moment from "moment";
import { AuthContext } from "contexts";
import { CompanySelect } from "components";

function Report() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const hospitalId = authContext.state.userInfo.profile.hospitalId;
  const [selectedHostpital, setSelectedHospital] = useState(hospitalId);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "days").startOf("day")
  );
  const [startTime, setStartTime] = useState(moment("08:00", "HH:mm"));
  const [endDate, setEndDate] = useState(moment());

  useEffect(() => {
    action.handleSidebarKey("report");
  }, []);

  const items = [
    {
      label: "Төхөөрөмж",
      key: "servicetype",
      children: (
        <ServiceType
          startTime={startTime}
          endTime={startTime}
          startDate={startDate}
          endDate={endDate}
          hospitalId={selectedHostpital}
        />
      ),
    },
  ];
  return (
    <div className="p-4">
      <div className="bg-white rounded-md p-5">
        <div className="flex gap-5 mb-3 ">
          <div>
            <CompanySelect
              // className="mb-0 w-[150px]"
              value={selectedHostpital}
              onChange={(e) => {
                setSelectedHospital(e);
              }}
            />
          </div>
          <div>
            Эхлэх огноо:{" "}
            <DatePicker value={startDate} onChange={(e) => setStartDate(e)} />
            <TimePicker
              format="HH:mm"
              defaultValue={startTime}
              disabled={true}
              changeOnBlur
            />
          </div>
          <div>
            Дуусах огноо:{" "}
            <DatePicker value={endDate} onChange={(e) => setEndDate(e)} />
            <TimePicker
              format="HH:mm"
              defaultValue={startTime}
              disabled={true}
              changeOnBlur
            />
          </div>
        </div>
        <Tabs items={items} />
      </div>
    </div>
  );
}

export default Report;
