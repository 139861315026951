import React, { useEffect, useState } from "react";

import { Form, MainTable } from "components";
import { instance } from "utils/axios";

import moment from "moment";

function ServiceType({
  startDate,
  endDate,
  servicetypeid,
  startTime,
  endTime,
  hospitalId,
}) {
  const [dataCT, setDataCT] = useState([]);
  const [dataMRI, setDataMRI] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, startTime, endTime, hospitalId]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/report/servicetype?startDate=${moment(startDate).format(
        "YYYY-MM-DD"
      )}T${moment(startTime).format("HH:mm")}&endDate=${moment(endDate).format(
        "YYYY-MM-DD"
      )}T${moment(endTime).format(
        "HH:mm"
      )}&servicetypeid=${2}&hospital=${hospitalId}`,
    })
      .then((res) => {
        setDataCT(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
    setLoading(true);
    instance({
      method: "get",
      url: `/report/servicetype?startDate=${moment(startDate).format(
        "YYYY-MM-DD"
      )}T${moment(startTime).format("HH:mm")}&endDate=${moment(endDate).format(
        "YYYY-MM-DD"
      )}T${moment(endTime).format(
        "HH:mm"
      )}&servicetypeid=${1}&hospital=${hospitalId}`,
    })
      .then((res) => {
        setDataMRI(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };
  const columns = [
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Тоо",
      dataIndex: "cnt",
      key: "cnt",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
    },
  ];
  const columnsMRI = [
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Тоо",
      dataIndex: "cnt",
      key: "cnt",
      render: (text, row) => <div>{Intl.NumberFormat().format(text)} </div>,
      sorter: (a, b) => a.cnt - b.cnt,
    },
  ];
  return (
    <div className="grid grid-cols-12 gap-5 mt-5">
      <div
        className={` bg-white rounded-lg shadow p-3 flex flex-col col-span-12 md:col-span-6`}
      >
        <div className="flex gap-5">
          <div className="border rounded-lg overflow-hidden flex-1">
            <MainTable dataTable={dataCT} columns={columns} title="CT DATA" />
          </div>
        </div>
      </div>
      <div
        className={` bg-white rounded-lg shadow p-3 flex flex-col col-span-12 md:col-span-6`}
      >
        <div className="flex gap-5">
          <div className="border rounded-lg overflow-hidden flex-1">
            <MainTable
              dataTable={dataMRI}
              columns={columnsMRI}
              title="MRI DATA"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceType;
