import React, { useEffect, useState, useContext, useRef } from "react";
import { BiSpreadsheet } from "react-icons/bi";
import {
  Button,
  Modal,
  Form as AntForm,
  Drawer,
  DatePicker,
  Select,
  message,
  Tooltip,
  Input,
  Tag,
} from "antd";

import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Form,
  MainTable,
  CompanySelect,
  PrintComponent,
  CustomerImages,
} from "components";
import { instance } from "utils/axios";
import fields from "./fields";
import columns, { columnNames } from "./columns";
import moment from "moment";
import BookingDetail from "./bookingDetail";
import { AuthContext } from "contexts";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ls from "utils/ls";
import { useSearchParams, useHistory } from "react-router-dom";
function Booking() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const hospitalId = authContext.state.userInfo.profile.hospitalId;
  const profile= authContext.state.userInfo.profile
  const [selectedHostpital, setSelectedHospital] = useState(
    ls.get("selectedHospital") ?? hospitalId
  );
  const role = authContext.state.userInfo.profile.role;
  const userId = authContext.state.userInfo.profile.id;
  const [data, setData] = useState([]);
  const [addition, setAddition] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [openImageId, setImageId] = useState(null);
  const [editData, setEditData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [sTechnician, setSTechnician] = useState(
    role == "Техникч" ? userId : -1
  );
  const [services, setServices] = useState([]);
  const [detailData, setDetailData] = useState(null);
  const [detailLoading, setDetailLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [startDate, setStartDate] = useState(moment().startOf("day"));
  const [startTime, setStartTime] = useState(moment("00:00", "HH:mm"));
  const [endTime, setEndTime] = useState(moment("23:59:59", "HH:mm"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [serviceType, setServiceType] = useState(ls.get("servicetypeid") ?? -1);
  const [hiddenColumns, setSetHiddenColumns] = useState(
    ls.get("hiddenColumns") ?? []
  );
  const [devices, setDevices] = useState([]);
  const [device, setDevice] = useState(-1);
  const [status, setStatus] = useState(-1);
  const [openRecep, setOpenRecep] = useState(false);
  const [form] = AntForm.useForm();
  const [searchText, setSearchText] = useState("");
  const [modal, contextHolder] = Modal.useModal();
  const [messageApi, messageHolder] = message.useMessage();
  const [ceItems, setCeItems] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const LIMIT = 200;

  useEffect(() => {
    
    action.handleSidebarKey("booking");
    let bookingId = searchParams.get("bookingId");
    if (bookingId) {
      let data = {
        isAdd: true,
        bookingData: {
          saleType: "normal",
          preBookingId: bookingId,
          cd: 1,
          currentDateTime: new Date(),
          hospitalId: selectedHostpital,
          currentDate: new Date(),
          deviceId: device == -1 ? null : device,
        },
      };
      if (serviceType != -1) data.bookingData.servicetypeid = serviceType;
      setEditData(data);
      setOpen(true);
      searchParams.delete("bookingId");
      setSearchParams(searchParams);
    }
  }, [searchParams]);
  useEffect(() => {
    fetchData();
  }, [
    startDate,
    endDate,
    startTime,
    endTime,
    status,
    serviceType,
    sTechnician,
    selectedHostpital,
    currentPage,
  ]);

  useEffect(() => {
    instance({
      method: "get",
      url: `/servicetype?active=1`,
    })
      .then((res) => {
        let tmp = [];
        res.data.map((item) => {
          if (item.id != 3)
            tmp.push({ label: item.name, value: item.id, text: item.name });
        });
        let tmp2 = [];
        if (res.data.length != 1) {
          tmp2.push({ label: "Бүгд", value: -1 });
          setServiceType(ls.get("servicetypeid") ?? -1);
        } else setServiceType(res.data[0].id);

        res.data.map((item) => {
          if (item.id != 3)
            tmp2.push({ label: item.name, value: item.id, text: item.name });
        });
        setServiceTypes(tmp);
        setServiceTypeOptions(tmp2);
      })
      .catch((err) => {});
    instance({
      method: "get",
      url: `/ceitems`,
    })
      .then((res) => {
        let tmp = [];
        res.data.map((item) => {
          tmp.push({
            label: item.name,
            value: item.id,
            servicetypeid: item.serviceTypeId,
          });
        });
        setCeItems(tmp);
      })
      .catch((err) => {});
  }, []);
  useEffect(() => {
    instance({
      method: "get",
      url: `/devices?hospital=${selectedHostpital}&servicetypeid=${serviceType}`,
    })
      .then((res) => {
        if (serviceType == -1) {
          let a = [{ value: -1, servicetypeid: -1, label: "Бүгд" }];

          res.data.map((item) =>
            a.push({
              value: item.id,
              servicetypeid: item.serviceTypeId,
              label: item.deviceName,
            })
          );
          setDevices(a);
          setDevice(-1);
        } else {
          setDevices(
            res.data.map((item) => ({
              ...item,
              value: item.id,
              servicetypeid: item.serviceTypeId,
              label: item.deviceName,
            }))
          );
          if (res.data.length > 0) {
            setDevice(res.data[0].id);
          } else {
            setDevice(null);
          }
        }
      })
      .catch((err) => {});
  }, [serviceType, selectedHostpital]);
  useEffect(() => {
    instance({
      method: "get",
      url: `/service?hospital=${selectedHostpital}`,
    })
      .then((res) => {
        setServices(
          res.data.map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        );
      })
      .catch((err) => {});
    instance({
      method: "get",
      url: `/systemusers?role=Техникч&status=Идэвхитэй&hospital=${selectedHostpital}`,
    })
      .then((res) => {
        setTechnicians(
          res.data.map((item) => ({
            value: item.id,
            label: `${item.lastname.charAt(0)} ${item.firstname}`,
          }))
        );
      })
      .catch((err) => {});
     
    // if(profile?.workType!="Үндсэн"){
    //    setDoctors([{
    //         value: userId,
    //         label: `${profile.lastname.charAt(0)}. ${profile.firstname}`,
    //         text: `${profile.lastname.charAt(0)}. ${profile.firstname}`,
    //       }])
    // }
    // else{
    
    instance({
      method: "get",
      url: `/systemusers?role=Эмч&status=Идэвхитэй&hospital=${selectedHostpital}`,
    })
      .then((res) => {
      
        setDoctors(
          res.data.map((item) => ({
            value: item.id,
            label: `${item.lastname.charAt(0)}. ${item.firstname}`,
            text: `${item.lastname.charAt(0)}. ${item.firstname}`,
          }))
        );
      })
      .catch((err) => {});
      // }
      
  }, [selectedHostpital]);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/customerbooking?offset=${currentPage}&limit=${LIMIT}
      &startdate=${moment(startDate).format("YYYY-MM-DD")}T${moment(
        startTime
      ).format("HH:mm:ss")}&enddate=${moment(endDate).format(
        "YYYY-MM-DD"
      )}T${moment(endTime).format(
        "HH:mm:ss"
      )}&servicetype=${serviceType}&status=${status}&technician=${sTechnician}
      &hospital=${selectedHostpital}
      &searchText=${searchText}`,
    })
      .then((res) => {
        setTotalCount(res.data.count);
        setData(res.data.rows);

        if (editData) {
          setEditData(res.data.find((item) => item.id === editData.id));
        }
      })
      .catch((err) => {})
      .then(() => {
        fetchAddition();
        setLoading(false)}
        );
    // setLoading(true);
    // if (role == "Ресейпшн")
    // instance({
    //   method: "get",
    //   url: `/reception/dashboard?date=${moment(startDate)
    //     .startOf("month")
    //     .format(
    //       "YYYY-MM-DD"
    //     )}&servicetype=${serviceType}&hospital=${selectedHostpital}`,
    // })
    //   .then((res) => {
    //     setDashboard(res.data);
    //   })
    //   .catch((err) => {})
    //   .then(() => setLoading(false));
  };
const fetchAddition = () => {
  setLoading(true);
  instance({
    method: "get",
    url: `/booking/addition?hospitalId=${selectedHostpital}`,
  })
    .then((res) => {
      setAddition(res.data);
    })
    .catch((err) => {})
    .then(() => setLoading(false));

  setLoading(true);
};
  const handleMore = (row) => {
    setDetailLoading(true);
    setSelectedCustomer(row);
    setShowDetail(true);
    instance({
      method: "get",
      url: `/customerbooking/${row.id}`,
    })
      .then((res) => {
        setDetailData(res.data);
      })
      .catch((err) => {})
      .then(() => setDetailLoading(false));
  };

  const handleResponse = (values) => {
    if (values.doctorId != userId) {
      modal.error({
        title: "Анхаарна уу !",
        content: "Өөр эмчид хариу хуваарьлагдсан эсвэл хоосон байна.",
      });
      return;
    } else {
      modal.confirm({
        title: "Анхаарна уу !",
        content: "Хариу гарсан гэдэгт итгэлтэй байна уу",
        okText: "Тийм",
        cancelText: "Үгүй",
        onOk() {
          return new Promise((resolve, reject) => {
            //  r/:id
            instance({
              method: "post",
              url: `/bookingservice/doctor/${values.id}`,
            })
              .then((res) => {
                resolve();
              })
              .catch((err) => {
                if (err.response.status == 403) {
                  modal.warning({
                    title: "Анхаарна уу !",
                    content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                  });
                }
                reject();
              })
              .then(() => {
                fetchData();
              });
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {},
      });
    }
  };
  const handleReceived = (values) => {
    if (!values.status) {
      modal.error({
        title: "Анхаарна уу !",
        content: "Шинжилгээний хариу гараагүй байна.",
      });
      return;
    }
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Энэ үйлдлийг хийхдээ итгэлтэй байна уу.",
      okText: "Тийм",
      cancelText: "Үгүй",
      onOk() {
        return new Promise((resolve, reject) => {
          //  r/:id
          instance({
            method: "post",
            url: `/bookingservice/receive/${values.id}`,
            data: {
              receiver: values.receiver,
              ebarimtStatus: values.ebarimtStatus,
            },
          })
            .then((res) => {
              resolve();
            })
            .catch((err) => {
              if (err.response.status == 403) {
                modal.warning({
                  title: "Анхаарна уу !",
                  content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                });
              }
              reject();
            })
            .then(() => {
              fetchData();
            });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  const handleSubmit = (values) => {
    setLoading(true);

    if (!editData.isAdd) {
      instance({
        method: "put",
        url: "/customerbooking",
        data: {
          ...values,
          bookingData: {
            ...values.bookingData,
            id: editData.bookingData.id,
            hospitalId: selectedHostpital,
            // ebarimtStatus: values.ebarimtStatus ? 1 : 0,
          },
          // id: editData.id,
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
    
      instance({
        method: "post",
        url: "/customerbooking",
        data: {
          ...values,
          bookingData: {
            ...values.bookingData,
            ebarimtStatus: values.ebarimtStatus ? 1 : 0,
            hospitalId: selectedHostpital,
          },
        },
      })
        .then((res) => {
          handleCloseModal();
          fetchData();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };
  const handleCdCall = (row) => {
    setOpenRecep(true);
    setEditData(row);
  };

  const handleSaveCdCall = (values) => {
    instance({
      method: "put",
      url: `/reception/cdcall`,
      data: {
        id: editData.id,
        receptionNote: values.receptionNote,
        cdGivenUserId: values.isCd ? userId : null,
        callUserId: values.isCall ? userId : null,
        answerPhone: values.answerPhone,
        amount: values.amount,
        addition: values.addition,
        descr: values.descr,
      },
    })
      .then((res) => {
        setOpenRecep(false);
        form.resetFields();
        fetchData();
      })
      .catch((err) => {
        if (err.response.status == 403) {
          modal.warning({
            title: "Анхаарна уу !",
            content: "Хэрэглэгчийн эрх хүрэхгүй байна",
          });
        } else {
          modal.error({
            title: "Анхаарна уу !",
            content: "Хадгалж чадсангүй",
          });
        }
      });

    //  reception / cdcall;
  };
  const handleDeleteService = (id, remove) => {
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Энэхүү мөр бичлэгийг устгах гэж байна",
      okText: "Устгах",
      cancelText: "Буцах",
      onOk() {
        return new Promise((resolve, reject) => {
          instance({
            method: "delete",
            url: `/bookingservice/${id}`,
          })
            .then((res) => {
              resolve();
              remove();
            })
            .catch((err) => {
              reject();
            });
          // .then(() => {
          //   // fetchData();
          // });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  const handleCancel = (values) => {
    if (values.status != 0) {
      modal.error({
        title: "Анхаарна уу !",
        content: "Шинжилгээг цуцлах боломжгүй байна.",
      });
      return;
    }
    modal.confirm({
      title: "Анхаарна уу !",
      content: "Шинжилгээг цуцлахдаа итгэлтэй байна уу.",
      okText: "Тийм",
      cancelText: "Үгүй",
      onOk() {
        return new Promise((resolve, reject) => {
          //  r/:id

          instance({
            method: "put",
            url: `/bookingservice/cancel/${values.id}`,
          })
            .then((res) => {
              resolve();
            })
            .catch((err) => {
              if (err.response.status == 403) {
                modal.warning({
                  title: "Анхаарна уу !",
                  content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                });
              }
              reject();
            })
            .then(() => {
              fetchData();
            });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
  const handleRecCloseModal = (values) => {
    setOpenRecep(false);
  };

  const handleSetDoctor = async (value, row, first) => {
    var data = first
      ? {
          id: row.id,
          doctorId: value ?? null,
          servicetypeid: row.servicetypeid,
        }
      : {
          id: row.id,
          doctor2Id: value ?? null,
          servicetypeid: row.servicetypeid,
        };
  
    if (first) {
      const res = await instance({
        method: "get",
        url: `/booking/doctorid/${row.id}`,
      });
      if (res.data.doctorId ) {
         await modal.confirm({
           title: "Анхаарна уу !",
           content: "Өөр эмчийн нэр байна солихдоо итгэлтэй байна уу.",
           okText: "Тийм",
           cancelText: "Үгүй",
           onOk() {
             instance({
               method: "put",
               url: `/booking/setdoctor`,
               data: data,
             })
               .then(() => {
                 fetchData();
               })
               .catch((err) => {
                 if (err.response.status == 403) {
                   modal.warning({
                     title: "Анхаарна уу !",
                     content: "Хэрэглэгчийн эрх хүрэхгүй байна",
                   });
                 }
               });
           },
           onCancel() {
            
           },
         });
      
      }
      else{
        instance({
          method: "put",
          url: `/booking/setdoctor`,
          data: data,
        })
          .then(() => {
            fetchData();
          })
          .catch((err) => {
            if (err.response.status == 403) {
              modal.warning({
                title: "Анхаарна уу !",
                content: "Хэрэглэгчийн эрх хүрэхгүй байна",
              });
            }
          });
      }
    }
   else{
      instance({
        method: "put",
        url: `/booking/setdoctor2`,
        data: data,
      })
        .then(() => {
          fetchData();
        })
        .catch((err) => {
          if (err.response.status == 403) {
            modal.warning({
              title: "Анхаарна уу !",
              content: "Хэрэглэгчийн эрх хүрэхгүй байна",
            });
          }
        });
   }
    
  };
  const handleAdd = () => {
    let data = {
      isAdd: true,
      bookingData: {
        saleType: "normal",
        cd: 1,
        currentDateTime: new Date(),
        currentDate: new Date(),
        hospitalId: selectedHostpital,
        deviceId: device == -1 ? null : device,
      },
    };
    if (serviceType != -1) data.bookingData.servicetypeid = serviceType;
    setEditData(data);
    setOpen(true);
  };

  const handleEdit = (row) => {
    if (row.status != 0) {
      modal.error({
        title: "Анхаарна уу !",
        content: "Хариу гарсан тул Шинжилгээний мэдээлэл өөрчлөх боломжгүй.",
      });
      return;
    }
    setEditData({
      isAdd: false,
      customerData: row.customer,
      bookingData: row,
    });
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };
  const handleRefresh = () => {
    fetchData();
  };
  const exportToCSV = () => {
    if (role == "Админ" || role == "CEO" || role == "Менежер") {
      setLoading(true);
      try {
        var Heading = [
          {
            hospitalName: "Эмнэлэг",
            currentDateTime: "Огноо",
            customerFirstname: "Үйлчлүүлэгчийн овог нэр",
            gender: "Хүйс",
            age: "Нас",
            serviceTypeName: "Төхөөрөмж",
            serviceNames: "Шинжлүүлэх эрхтэн",
            ceitem: "Тодосгогч бодис",
            isAns: "Унтуулагч",
            onlyCe: "Only CE",
            isCe: "Тодосгогч",
            insurance: "ЭМД",
            amount: "Үнийн дүн",
            status: "Гарсан эсэх",
            docHour: "Гарсан цаг",
            doctor: "Эмч",
            doctor2: "Зөвлөх эмч",
            answerPhone: "Хариу авах утас",
            isReceived: "Хүлээж авсан",
            receiver: "Хүлээж авсан хүн",
            reception: "Reception",
            receptionNote: "Тэмдэглэл",
            technicalUser: "Техникч",
            descr: "Тайлбар",
          },
        ];

        var headers = Heading.map(function (item) {
          return Object.keys(item);
        });

        const ws = XLSX.utils.json_to_sheet(Heading, {
          header: headers,
          skipHeader: true,
          origin: -1, //ok
        });
        const extractedData = data.map((obj) => {
          return headers.reduce((acc, key) => {
            if (obj.hasOwnProperty(key)) {
            
              acc[key] = obj[key];
            }
            return acc;
          }, {});
        });
        const columnLengths = headers.map((header) =>
          Math.max(
            header.length,
            ...data.map((obj) => String(obj[header] || "").length)
          )
        );
        const columnWidths = columnLengths.map((len) => ({ width: len * 1.2 }));
        ws["!cols"] = columnWidths;
        // data.map();
        //  ws["!cols"] = wscols;
        XLSX.utils.sheet_add_json(ws, extractedData, {
          header: headers,
          skipHeader: true,
          origin: -1, //ok
        });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data1 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(
          data1,
          `Шинжилгээ /${moment(startDate).format("YYYY-MM-DD")} - ${moment(
            endDate
          ).format("YYYY-MM-DD")}/` + fileExtension
        );
        setLoading(false);
      } catch (ex) {
        modal.error({
          title: "Анхаарна уу !",
          content: "Эксэл рүү гаргахад алдаа гарлаа.",
        });
        setLoading(false);
      }
    } else {
      modal.warning({
        title: "Анхаарна уу !",
        content: "Хэрэглэгчийн эрх хүрэхгүй байна",
      });
    }
  };
  const disabledDate = (current) => {
    return current && current < moment().subtract(16, "days").endOf("day");
  };
  // const handleCurrentPage = (offset) => {
  //   setCurrentPage(offset);
  //   setOffset(offset);
  //   fetchData();
  // };

  const handleColumnChange = (value) => {
    setSetHiddenColumns(value);
    ls.set("hiddenColumns", value);
  };
  return (
    <div className="flex flex-col px-3 pb-3">
      <div className="grid grid-cols-24 gap-2">
        <div className="col-span-24 md:col-span-4">
            <CompanySelect
              className="mb-0 w-[180px]"
              value={selectedHostpital}
              onChange={(e) => {
                ls.set("selectedHospital", e);
                setSelectedHospital(e);
              }}
            />
       
        </div>
        <div className="col-span-12 md:col-span-6  md:items-center ">
          <div className="flex items-center ">
            <DatePicker
              className="w-[140px]"
              value={startDate}
              onChange={(e) => setStartDate(e)}
            />

            <DatePicker
              className="w-[140px]"
              value={endDate}
              onChange={(e) => setEndDate(e)}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-5 ">
          <div className="flex items-center ">
            Төхөөрөмж:
            <Select
              className="mb-0 w-[100px]"
              value={serviceType}
              options={serviceTypeOptions}
              onChange={(e) => {
                ls.set("servicetypeid", e);
                setServiceType(e);
              }}
            />
            <Select
              className="mb-0 w-[100px]"
              value={device}
              options={devices}
              onChange={(e) => {
                setDevice(e);
              }}
            />
          </div>
        </div>
       
        <div className="col-span-12 md:col-span-3  items-center gap-2">
          <div className="flex items-center ">
            Хариу:
            <Select
              className="mb-0 w-[100px]"
              value={status}
              onChange={(value) => {
                setStatus(value);
              }}
              options={[
                { label: "Бүгд", value: -1 },
                { label: "Тийм", value: 1 },
                { label: "Үгүй", value: 0 },
              ]}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-3  items-center gap-2">
          <div className="flex items-center ">
            Техникч:
            <Select
              className="mb-0 w-[150px]"
              value={sTechnician}
              onChange={(value) => {
                setSTechnician(value);
                setData(data.filter((x) => x.technicalUser == value));
              }}
              options={[
                { label: "Бүгд", value: -1 },
                { label: "М. Батсугар", value: 84 },
                ...technicians,
              ]}
            />
          </div>
        </div>
        <div className="col-span-12 md:col-span-1  items-center gap-2">
          <Button
            type="primary"
            onClick={exportToCSV}
            // disabled={}
          >
            <BiSpreadsheet />
          </Button>
        </div>
      </div>
      <div className="col-span-6 md:flex items-center gap-2">
        <span>Нэр РД хайх:</span>
        <Input
          className="mb-0 w-[320px]"
          // ref={searchInput}
          placeholder={`Овог нэр эсвэл Регистрийн дугаар`}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onPressEnter={() => {
            fetchData();
          }}
          style={{
            marginTop: 8,

            marginBottom: 8,
            display: "block",
          }}
        />
        {/* </div>
      <div className="col-span-6 md:col-span-6 flex items-center gap-2"> */}
        <Button
          type="primary"
          onClick={() => fetchData()}
          icon={<SearchOutlined />}
          size="medium"
          style={{ width: 90 }}
        >
          Хайх
        </Button>
        <Select
          mode="multiple"
          size={"middle"}
          placeholder="Багана хасах"
          value={hiddenColumns}
          onChange={handleColumnChange}
          style={{ width: "10%" }}
          options={columnNames()}
          maxTagCount={"responsive"}
          maxTagPlaceholder={() => (
            <Tooltip>
              <span>Хассан баганууд</span>
            </Tooltip>
          )}
        />
        <Tag color={addition > 0 ? "red" : ""}>Яаралтай: {addition}</Tag>
      </div>
      {/* </div> */}
      <MainTable
        dataTable={data}
        columns={columns({
          currentPage,
          doctors,
          serviceTypes,
          handleSetDoctor,
          role,
          handleResponse,
          handleCancel,
          handleEdit,
          handleCdCall,
          handleReceived,
          handleMore,
          messageApi,
          hiddenColumns,
          openPrint: (row) => {
            setEditData({
              hospitalId: row.hospitalId,
              name: row.customerFirstname,
              currentDate: row.currentDate,
              serviceName: `${row.serviceNames}${row.isCe ? "+CE" : ""} /${
                row.serviceTypeName
              }/`,
            });
            setOpenPrint(true);
          },
          openImage: (bookingId, status) => {
            // if (status) {
            //   modal.error({
            //     title: "Анхаарна уу !",
            //     content: "Шинжилгээний хариу гарсан байна.",
            //   });
            //   return;
            // }
            setImageId(bookingId);
            setOpenImage(true);
          },
        }).filter((col) => col.visible != false)}
        setCurrentPage={setCurrentPage}
        loading={loading}
        scroll={{ y: "calc(100vh - 320px)" }}
        pagination={{
          total: totalCount,
        }}
        pageSize={LIMIT}
        title={`Шинжилгээ /${moment(startDate).format("YYYY-MM-DD")}/`}
        handleAdd={handleAdd}
        handleRefresh={handleRefresh}
      />
      <Modal
        title={"Шинжилгээ"}
        open={open}
        destroyOnClose
        width={1000}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields({
            technicians,
            customers,
            services,
            removeFunc: (id) => handleDeleteService(id),
            form,
            serviceTypes,
            devices,
            ceItems,
          })}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          className="gap-4"
        />
      </Modal>
      <Modal
        title={"Reception"}
        open={openRecep}
        destroyOnClose
        width={500}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleRecCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={[
            {
              label: "CD авсан",
              name: "isCd",
              type: "checkbox",

              className: "col-span-6 md:col-span-6 mb-0",
              inputProps: {
                className: "w-full",
              },
            },
            {
              label: "Утсаар ярьсан",
              name: "isCall",
              type: "checkbox",
              className: "col-span-6 md:col-span-6 mb-0",
              inputProps: {
                className: "w-full",
              },
            },
            {
              label: "Нэмэлт хариу",
              name: "addition",
              className: "col-span-6 md:col-span-3 mb-0",
              type: "checkbox",
              inputProps: {
                className: "w-full",
              },
            },
            {
              label: "Утас",
              name: "answerPhone",
              type: "input",
              className: "col-span-12 md:col-span-12 mb-0",
              inputProps: {
                className: "w-full",
              },
            },

            {
              label: "Дүн",
              name: "amount",
              type: "number",
              className: "col-span-12 md:col-span-12 mb-0",
              inputProps: {
                className: "w-full",
              },
            },
            {
              label: "Тэмдэглэл",
              name: "receptionNote",
              type: "textarea",
              className: "col-span-12 md:col-span-12 mb-0",
              inputProps: {
                className: "w-full",
              },
            },
            {
              label: "Тайлбар",
              name: "descr",
              type: "textarea",
              className: "col-span-12 md:col-span-12 mb-0",
              inputProps: {
                className: "w-full",
              },
            },
          ]}
          layout="vertical"
          onFinish={handleSaveCdCall}
          editData={editData}
          className="gap-4"
        />
      </Modal>
      <PrintComponent
        openPrint={openPrint}
        data={editData}
        setOpenPrint={setOpenPrint}
      ></PrintComponent>
      <CustomerImages
        open={openImage}
        bookingId={openImageId}
        handleOpenImage={setOpenImage}
        role={role}
      ></CustomerImages>
      <Drawer
        open={showDetail}
        onClose={() => setShowDetail(false)}
        title={`${moment(selectedCustomer?.currentDateTime).format(
          "YYYY-MM-DD"
        )} ${moment(selectedCustomer?.currentDateTime).format("HH:mm")}`}
        width={700}
        bodyStyle={{ padding: 10 }}
      >
        {detailLoading ? (
          <div className="flex justify-center items-center h-full">
            <LoadingOutlined className="text-[40px]" />
          </div>
        ) : (
          <BookingDetail
            data={detailData}
            rowData={selectedCustomer}
            refreshData={handleMore}
          />
        )}
      </Drawer>
      {contextHolder}
      {messageHolder}
    </div>
  );
}

export default Booking;
